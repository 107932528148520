import React from 'react';
import Dashboard from './components/dashboard';
import './App.css';


function App() {
  return (
    <div className="App">
        
        <Dashboard></Dashboard>     
    </div>
  );
}

export default App;
